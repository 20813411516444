html {
	scroll-behavior: smooth;
	height: 100%;
	width: 100%;
  overflow-y: hidden;
}

body {
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}