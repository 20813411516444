html, body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(#123, #111); }

.snowflake {
  --size: 1vw;
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh; }

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0); }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0); } }

.snowflake:nth-child(1) {
  --size: 0.4vw;
  --left-ini: -9vw;
  --left-end: 7vw;
  left: 36vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -3s; }

.snowflake:nth-child(2) {
  --size: 0.8vw;
  --left-ini: 5vw;
  --left-end: -7vw;
  left: 3vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(3) {
  --size: 0.8vw;
  --left-ini: 4vw;
  --left-end: -5vw;
  left: 32vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -3s; }

.snowflake:nth-child(4) {
  --size: 0.2vw;
  --left-ini: 4vw;
  --left-end: -4vw;
  left: 22vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -5s; }

.snowflake:nth-child(5) {
  --size: 0.4vw;
  --left-ini: 8vw;
  --left-end: -9vw;
  left: 84vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -6s; }

.snowflake:nth-child(6) {
  --size: 0.8vw;
  --left-ini: 4vw;
  --left-end: 3vw;
  left: 25vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -4s; }

.snowflake:nth-child(7) {
  --size: 1vw;
  --left-ini: 6vw;
  --left-end: 2vw;
  left: 98vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -7s; }

.snowflake:nth-child(8) {
  --size: 1vw;
  --left-ini: 8vw;
  --left-end: 9vw;
  left: 4vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -5s; }

.snowflake:nth-child(9) {
  --size: 0.8vw;
  --left-ini: 4vw;
  --left-end: 1vw;
  left: 36vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -6s; }

.snowflake:nth-child(10) {
  --size: 0.2vw;
  --left-ini: -7vw;
  --left-end: -4vw;
  left: 81vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -7s; }

.snowflake:nth-child(11) {
  --size: 0.4vw;
  --left-ini: 0vw;
  --left-end: 3vw;
  left: 51vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -5s; }

.snowflake:nth-child(12) {
  --size: 0.8vw;
  --left-ini: -3vw;
  --left-end: 0vw;
  left: 46vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -9s; }

.snowflake:nth-child(13) {
  --size: 0.8vw;
  --left-ini: 0vw;
  --left-end: 5vw;
  left: 80vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -3s; }

.snowflake:nth-child(14) {
  --size: 0.2vw;
  --left-ini: -3vw;
  --left-end: 7vw;
  left: 56vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -8s; }

.snowflake:nth-child(15) {
  --size: 0.8vw;
  --left-ini: 2vw;
  --left-end: 2vw;
  left: 9vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -4s; }

.snowflake:nth-child(16) {
  --size: 0.4vw;
  --left-ini: -6vw;
  --left-end: 6vw;
  left: 61vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -4s; }

.snowflake:nth-child(17) {
  --size: 0.4vw;
  --left-ini: -8vw;
  --left-end: 9vw;
  left: 36vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(18) {
  --size: 0.6vw;
  --left-ini: -1vw;
  --left-end: 10vw;
  left: 29vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -4s; }

.snowflake:nth-child(19) {
  --size: 1vw;
  --left-ini: 5vw;
  --left-end: 7vw;
  left: 22vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(20) {
  --size: 0.4vw;
  --left-ini: 2vw;
  --left-end: 0vw;
  left: 16vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -2s; }

.snowflake:nth-child(21) {
  --size: 1vw;
  --left-ini: -3vw;
  --left-end: -4vw;
  left: 16vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -7s; }

.snowflake:nth-child(22) {
  --size: 0.4vw;
  --left-ini: -4vw;
  --left-end: 1vw;
  left: 56vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(23) {
  --size: 0.8vw;
  --left-ini: 6vw;
  --left-end: -7vw;
  left: 88vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(24) {
  --size: 0.6vw;
  --left-ini: 4vw;
  --left-end: 10vw;
  left: 90vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(25) {
  --size: 0.8vw;
  --left-ini: 3vw;
  --left-end: -8vw;
  left: 61vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -2s; }

.snowflake:nth-child(26) {
  --size: 0.6vw;
  --left-ini: 10vw;
  --left-end: 0vw;
  left: 99vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -9s; }

.snowflake:nth-child(27) {
  --size: 0.4vw;
  --left-ini: 4vw;
  --left-end: -3vw;
  left: 43vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(28) {
  --size: 0.4vw;
  --left-ini: 9vw;
  --left-end: 3vw;
  left: 59vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -2s; }

.snowflake:nth-child(29) {
  --size: 0.2vw;
  --left-ini: 1vw;
  --left-end: 5vw;
  left: 56vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -6s; }

.snowflake:nth-child(30) {
  --size: 1vw;
  --left-ini: 5vw;
  --left-end: 7vw;
  left: 61vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -6s; }

.snowflake:nth-child(31) {
  --size: 0.4vw;
  --left-ini: -7vw;
  --left-end: 10vw;
  left: 43vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -4s; }

.snowflake:nth-child(32) {
  --size: 0.6vw;
  --left-ini: 1vw;
  --left-end: -2vw;
  left: 87vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -9s; }

.snowflake:nth-child(33) {
  --size: 1vw;
  --left-ini: 10vw;
  --left-end: -4vw;
  left: 78vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -8s; }

.snowflake:nth-child(34) {
  --size: 0.8vw;
  --left-ini: -4vw;
  --left-end: 3vw;
  left: 89vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -2s; }

.snowflake:nth-child(35) {
  --size: 1vw;
  --left-ini: -5vw;
  --left-end: 8vw;
  left: 54vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -6s; }

.snowflake:nth-child(36) {
  --size: 0.6vw;
  --left-ini: -6vw;
  --left-end: -9vw;
  left: 87vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -8s; }

.snowflake:nth-child(37) {
  --size: 0.2vw;
  --left-ini: 7vw;
  --left-end: 10vw;
  left: 55vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -7s; }

.snowflake:nth-child(38) {
  --size: 0.4vw;
  --left-ini: 10vw;
  --left-end: -2vw;
  left: 23vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -4s; }

.snowflake:nth-child(39) {
  --size: 1vw;
  --left-ini: 9vw;
  --left-end: -8vw;
  left: 82vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -7s; }

.snowflake:nth-child(40) {
  --size: 0.4vw;
  --left-ini: 6vw;
  --left-end: -6vw;
  left: 94vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -2s; }

.snowflake:nth-child(41) {
  --size: 1vw;
  --left-ini: -2vw;
  --left-end: -1vw;
  left: 30vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -5s; }

.snowflake:nth-child(42) {
  --size: 1vw;
  --left-ini: -5vw;
  --left-end: 1vw;
  left: 74vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -6s; }

.snowflake:nth-child(43) {
  --size: 0.2vw;
  --left-ini: 8vw;
  --left-end: -6vw;
  left: 41vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -7s; }

.snowflake:nth-child(44) {
  --size: 0.2vw;
  --left-ini: 4vw;
  --left-end: -2vw;
  left: 36vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(45) {
  --size: 0.8vw;
  --left-ini: -9vw;
  --left-end: 7vw;
  left: 64vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -1s; }

.snowflake:nth-child(46) {
  --size: 0.2vw;
  --left-ini: 8vw;
  --left-end: -1vw;
  left: 90vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -6s; }

.snowflake:nth-child(47) {
  --size: 0.2vw;
  --left-ini: -5vw;
  --left-end: -3vw;
  left: 48vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -10s; }

.snowflake:nth-child(48) {
  --size: 0.2vw;
  --left-ini: 3vw;
  --left-end: 1vw;
  left: 4vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -8s; }

.snowflake:nth-child(49) {
  --size: 1vw;
  --left-ini: 5vw;
  --left-end: 8vw;
  left: 16vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -4s; }

.snowflake:nth-child(50) {
  --size: 0.8vw;
  --left-ini: 2vw;
  --left-end: 8vw;
  left: 95vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -6s; }

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
  filter: blur(1px); }
